<template>
<main>
  <template v-if="item.description">
    <img id="cover-img" :src="coverImg" class="full-width">

    <section id="description">
      <div class="dgrid-body cover">
        <div class="dgrid-container flow">
           <h2>{{ title }}</h2>
           <div id="info" class="stack-horizontal" data-align="start">
             <img :src="logo">
             <div class="text--body1">
               <p v-if="website"><a :href="website" target="_blank" rel="noopener noreferrer">{{ websiteShot }}</a></p>
               <p>{{ address }}</p>
             </div>
           </div>
           <p v-html="parsedDescription" class="marked"></p>
        </div>
        
      </div>
    </section>

    <section id="databases" v-if="databases && databases.length">
      <div class="dgrid-wrapper-scrollx">
        <div class="dgrid-body flow">
          <h3>{{ $t('ente_titolo_sez_database_correlati') }} {{ title }}</h3>
          
            <div class="dgrid-container dgrid-fix-4-scroll-5-6 more-shadow">
              
              <base-cg-database
                v-for="db in databases"
                :key="db.id"
                :item="db"
                @clicked="$router.push({name: 'explore', query: {database: db.name}})"
              />          

            </div>
          </div>
      </div>
    </section>
    
    <section id="stories" v-if="stories && stories.length">
      <div class="dgrid-body flow">
        <h3>{{ $t('ente_titolo_sez_storie_correlate') }} {{ title }}</h3>

        <div class="dgrid-wrapper-scrollx">
          <div class="dgrid-container dgrid-fix-3-scroll-5-6">

            <base-cs-story
              v-for="i in stories" :key="i.id"
              :item="i"
              @clicked="$router.push({name: 'story', params: { slug: i.slug }})"
            />

          </div>
        </div>

      </div>
    </section>
    
    <section id="sheets" v-if="!sheets || sheetsCount">
      <div class="dgrid-body flow">
        <div class="stack-horizontal" data-align="end" data-justify="spaced">
          <h3>{{ $t('ente_titolo_sez_schede_correlate') }} {{ title }} <span  v-if="sheetsCount">({{ sheetsCount }})</span></h3>
          <base-button-ghost :text="$t('global_bottone_mostra_su_mappa')" icon="arrow-right-button" @clicked="goToExplore"/>
        </div>
        
        <div class="dgrid-container dgrid-minw-maxn">

          <template v-if="!sheets">
            <base-cs-sheet
              v-for="i in 8"
              :key="i"
              skeleton
            />            
          </template>

          <template v-else>
            <base-cs-sheet 
              v-for="s in sheets"
              :key="s.id"
              :item="s"
              ignore-max-width
              @clicked="goToSheet(s.slug)"
            />            
          </template>

        </div>
        <div v-if="sheetsCount && sheets.length < sheetsCount" class="cover">
          <base-button :text="$t('global_bottone_espandi')" @clicked="getSheets"/>
        </div>
      </div>
    </section>
  </template>
</main>
</template>

<script>
import cmsItem from '@mixins/cmsItem.js';
import { mapState, mapActions } from 'vuex';
import utils from '@js/utils.js';
import * as marked from 'marked';

export default {
  name: 'Institution',
  mixins: [ cmsItem ],
  props: {
    // id: { //route prop
    //   type: String
    // },
    slug: { //route prop
      type: String,
      required: true
    }
  },
  data() {
    return {
      itemType: 'institutions',
      relatedSheetsPage: 1,
      pageSize: 50
    }
  },
  watch: {
    item(n, o) {
      // console.log("new item", n, o)
      if (n.name && !o.name) this.getSheets();
    }
  },
  computed: {
    ...mapState('interface', ['mobile']),
    coverImg() {
      return utils.parseMediaUrl(this.item.cover_image);
    },
    title() {
      return this.item.name;
    },
    logo() {
      return utils.parseMediaUrl(this.item.logo);
    },
    website() {
      return this.item.site;
    },
    websiteShot() {
      return utils.beautifyUrl(this.item.site);
    },
    address() {
      return this.item.address;
    },
    description() {
      return this.item.description;
    },
    databases() {
      return this.item.databases;
    },
    stories() {
      let all = this.item.stories;
      return all.filter(el => !el.status || el.status == 'published');
    },
    sheets() {
      return this.item.sheets ? this.item.sheets.items : undefined;
    },
    sheetsCount() {
      return this.item.sheets ? this.item.sheets.count : 0;
    },
    parsedDescription() {
      return marked.parse(this.description);
    },
    id() {
      return this.item?.id;
    }
  },
  methods: {
    ...mapActions('cms', ['getSheetsRelated']),
    getSheets() {
      if (this.item && this.title) {
        let args = {
          listName: 'institutions',
          id: this.id,
          args: {
            page_size: this.pageSize,
            page: this.relatedSheetsPage,
            institution: this.title
          }
        }
        this.getSheetsRelated(args);
        this.relatedSheetsPage++;        
      }
    },
    goToExplore() {
      this.$router.push({name: 'explore', query: {'institution': this.title}});
    },
    goToSheet(slug) {
      this.$router.push({name: 'sheet', params: {slug}})
    },
    parseMediaUrl(url) {
      return utils.parseMediaUrl(url);
    }
  },
  mounted() {
    this.getSheets();
  }
}
</script>

<style lang="scss" src="@css/views/ViewInstitution.scss"></style>
