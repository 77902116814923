import { render, staticRenderFns } from "./ViewInstitution.vue?vue&type=template&id=7eaf74ee"
import script from "./ViewInstitution.vue?vue&type=script&lang=js"
export * from "./ViewInstitution.vue?vue&type=script&lang=js"
import style0 from "@css/views/ViewInstitution.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports